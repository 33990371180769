.anchor-target {
  display: block;
  position: relative;
  visibility: hidden;
}

.last-child-margin-reset > :last-child {
  margin-bottom: 0;
}

p {
  line-height: 1.39;
}
li {
  line-height: 1.39;
}

ol {
  margin-left: 1rem;
  counter-reset: item;
  list-style: none outside none;
}
ol > li:before {
  content: counter(item) ') ';
  counter-increment: item;
  position: absolute;
  left: -2rem;
}

ol > li {
  position: relative;
}

button:focus {
  outline: none;
}

/* root element of the app */
#__next {
  position: relative;
  display: flex;
}

/* Do not display section divider in the first section */
/* mb-section-top-mobile is the section divider class name taken from src/contentTypes/organisms/Section/index.tsx */
section:first-of-type hr.mb-section-top-mobile {
  display: none;
}

section:last-of-type {
  margin-bottom: 4rem;
}

.research-css-arrow::after {
  border-color: transparent;
  border-style: solid;
  content: '';
  height: 0;
  position: absolute;
  pointer-events: none;
  top: 15px;
  width: 0;
  left: -10px;
  border-right-color: #feece0;
  border-width: 9px 10px 9px 0;
}

@media (max-width: 1024px) {
  .research-css-arrow::after {
    border-width: 0 9px 10px 9px;
    border-bottom-color: #feece0;
    border-right-color: transparent;
    top: -10px;
    left: 11px;
  }
}

.ecoupon-css-arrow::after {
  border-color: transparent;
  border-style: solid;
  content: '';
  height: 0;
  position: absolute;
  pointer-events: none;
  top: 15px;
  width: 0;
  left: -10px;
  border-right-color: #f3f6fe;
  border-width: 9px 10px 9px 0;
}

@media (max-width: 1024px) {
  .ecoupon-css-arrow::after {
    border-width: 0 9px 10px 9px;
    border-bottom-color: #f3f6fe;
    border-right-color: transparent;
    top: -10px;
    left: 11px;
  }
}

.disclosure-link-drawer {
  animation: slide 0.4s ease;
}

.quote-card-ldquo {
  font-size: 144px;
  line-height: 1;
  position: absolute;
  top: 6px;
  left: 0;
  right: 0;
  text-align: center;
}

@media (min-width: 768px) and (max-width: 1023px) {
  .quote-card-ldquo {
    font-size: 96px;
    top: 10px;
  }
}

@media (max-width: 768px) {
  .disclosure-link-drawer {
    animation: slide-mobile 0.4s ease;
  }
}

.focus-outline:focus {
  border: 1px solid #5599ff;
}

.input-icon-position-y {
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.affirm-modal-trigger {
  color: #0f56a6;
  text-decoration: underline;
}

@keyframes slide {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes slide-mobile {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes slide-up {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0);
  }
}

.smooth-scroll {
  -webkit-overflow-scrolling: touch;
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  /* Workaround for IE11 incompatible usages of flex-1 */
  .ie11-flex-auto {
    flex-basis: auto;
  }

  /* Workaround for IE11 flexbug where text does not wrap */
  .ie11-flex-wrap {
    overflow-x: auto;
  }
}

@supports (padding: max(0px)) {
  .fullScreenSafeArea {
    padding-top: max(0px, env(safe-area-inset-top));
  }
}

.cms-editormode:hover {
  outline: 3px solid #0f56a6;
  outline-offset: 4px;
  cursor: pointer;
}

@font-face {
  font-family: 'GoodRx Bolton';
  src: url('../static/fonts/bolton/WOFF2/regular.woff2') format('woff');
  font-weight: 400;
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'GoodRx Bolton';
  src: url('../static/fonts/bolton/WOFF2/medium.woff2') format('woff2');
  font-weight: 500;
  font-weight: medium;
  font-style: normal;
}
@font-face {
  font-family: 'GoodRx Bolton';
  src: url('../static/fonts/bolton/WOFF2/bold.woff2') format('woff2');
  font-weight: 700;
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: 'GoodRx Bolton';
  src: url('../static/fonts/bolton/WOFF2/regularItalic.woff2') format('woff2');
  font-weight: 400;
  font-weight: normal;
  font-style: italic;
}
@font-face {
  font-family: 'GoodRx Bolton';
  src: url('../static/fonts/bolton/WOFF2/mediumItalic.woff2') format('woff2');
  font-weight: 500;
  font-weight: medium;
  font-style: italic;
}
@font-face {
  font-family: 'GoodRx Bolton';
  src: url('../static/fonts/bolton/WOFF2/boldItalic.woff2') format('woff2');
  font-weight: 700;
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'GoodRx Moon';
  src: url('../static/fonts/moon/WOFF2/regular.woff2') format('woff');
  font-weight: 400;
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'GoodRx Moon';
  src: url('../static/fonts/moon/WOFF2/demi.woff2') format('woff2');
  font-weight: 500;
  font-weight: semi-bold;
  font-style: normal;
}
@font-face {
  font-family: 'GoodRx Moon';
  src: url('../static/fonts/moon/WOFF2/bold.woff2') format('woff2');
  font-weight: 700;
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: 'GoodRx Moon';
  src: url('../static/fonts/moon/WOFF2/regularItalic.woff2') format('woff2');
  font-weight: 400;
  font-weight: normal;
  font-style: italic;
}
@font-face {
  font-family: 'GoodRx Moon';
  src: url('../static/fonts/moon/WOFF2/demiItalic.woff2') format('woff2');
  font-weight: 500;
  font-weight: semi-bold;
  font-style: italic;
}
@font-face {
  font-family: 'GoodRx Moon';
  src: url('../static/fonts/moon/WOFF2/boldItalic.woff2') format('woff2');
  font-weight: 700;
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'GoodRx Goodall';
  src: url('../static/fonts/goodall/WOFF2/regular.woff2') format('woff2');
  font-weight: 400;
  font-weight: normal;
  font-style: normal;
}
